html,
body {
  height: 100%;
}

td,
th,
thead {
  text-align: left;
}

.a-sticker {
  &.-blue {
    background-color: var(--major-highlight-blue__enabled__fill__default);
    color: var(--small__enabled__front__default);
  }

  &.-red {
    background-color: var(--major-signal-error__enabled__fill__default);
    color: var(--small__enabled__front__default);
  }
}

.m-step-indicator__node {
  color: var(--bosch-gray-40);
  cursor: pointer;
}

.m-step-indicator__step.-active > .m-step-indicator__node {
  color: var(--major-accent__enabled__front__default);
}

.m-step-indicator__step.disabled > .m-step-indicator__node {
  background-color: var(--neutral__disabled__fill__default);
  color: var(--neutral__disabled__front__default);
  cursor: not-allowed;
}

.m-step-indicator__step.complete-disabled > .m-step-indicator__node {
  background-color: var(--major-accent__enabled__fill__default);
  color: var(--major-accent__enabled__front__default);
  cursor: not-allowed;
}

.m-step-indicator__step.not-allowed > .m-step-indicator__node {
  cursor: not-allowed !important;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap: 1rem;
}

.grow {
  flex-grow: 1;
}

.a-chip:last-of-type {
  margin-right: 1rem;
}

app-context-menu {
  .a-checkbox {
    width: 100%;
    padding: 0.75rem;
  }
}

body:has(dialog[open]) {
  overflow: hidden;
}

.font-weight-bold {
  font-weight: bold;
}

.width-100 {
  width: 100%;
}

.width-80 {
  width: 80%;
}

.width-75 {
  width: 75%;
}

.width-65 {
  width: 65%;
}

.width-55 {
  width: 55%;
}

.width-50 {
  width: 50%;
}

.width-45 {
  width: 45%;
}

.width-35 {
  width: 35%;
}

.width-25 {
  width: 25%;
}

.width-20 {
  width: 20%;
}

.margin-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 0.313rem;
}

.mt-7 {
  margin-top: 0.438rem;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mt-50 {
  margin-top: 3.125rem;
}

.ml-5 {
  margin-left: 0.313rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.ml-16 {
  margin-left: 1rem;
}

.ml-17 {
  margin-left: 1.063rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 0.313rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.mb-50 {
  margin-bottom: 3.125rem;
}

.mr-16 {
  margin-right: 1rem;
}

.height-100 {
  height: 100%;
}
